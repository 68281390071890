* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.navbar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1.2rem 6%;
	background-color: #1a1a1a;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
	position: fixed;
	width: 100%;
	top: 0;
	z-index: 1000;
}

.logo {
	display: flex;
	align-items: center;
	font-weight: 700;
	font-size: 1.5rem;
	color: #2ac18a;
}

.logo-icon {
	margin-right: 8px;
	display: flex;
	align-items: center;
	color: #2ac18a;
}

.nav-links {
	display: flex;
	list-style: none;
	gap: 2rem;
}

.nav-link {
	text-decoration: none;
	color: #b8b8b8;
	font-weight: 500;
	font-size: 1rem;
	transition: color 0.3s;
	position: relative;
}

.nav-link:hover {
	color: #ffffff;
}

.nav-link::after {
	content: '';
	position: absolute;
	width: 0;
	height: 2px;
	bottom: -5px;
	left: 0;
	background: #2ac18a;
	transition: width 0.3s;
}

.nav-link:hover::after {
	width: 100%;
}

.nav-actions {
	display: flex;
	align-items: center;
	gap: 1rem;
}

.btn {
	padding: 0.75rem 1.5rem;
	border-radius: 8px;
	font-weight: 600;
	font-size: 0.875rem;
	cursor: pointer;
	transition: all 0.3s;
}

.btn-outline {
	border: 1.5px solid #2ac18a;
	color: #2ac18a;
	background: transparent;
}

.btn-outline:hover {
	background-color: rgba(42, 193, 138, 0.1);
}

.btn-primary {
	background: #2ac18a;
	color: #121212;
	border: none;
}

.btn-primary:hover {
	opacity: 0.9;
	transform: translateY(-2px);
	box-shadow: 0 4px 12px rgba(42, 193, 138, 0.3);
}

.mobile-menu-btn {
	display: none;
	background: none;
	border: none;
	font-size: 1.5rem;
	cursor: pointer;
	color: #b8b8b8;
}

.hero {
	margin-top: 80px;
	padding: 6rem 6%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: #121212;
}

.hero-content {
	max-width: 550px;
}

.hero-title {
	font-size: 3.5rem;
	line-height: 1.2;
	color: #ffffff;
	margin-bottom: 1.5rem;
	font-weight: 800;
}

.hero-subtitle {
	font-size: 1.2rem;
	color: #b8b8b8;
	margin-bottom: 2rem;
	line-height: 1.6;
}

.hero-buttons {
	display: flex;
	gap: 1rem;
}

.hero-image {
	max-width: 45%;
	overflow: hidden;
	border-radius: 10px;
}

.hero-image img {
	width: 100%;
	height: auto;
	max-width: 100%;
	display: block;
}
.highlight {
	background: linear-gradient(135deg, #2ac18a 0%, #1a8062 100%);
	-webkit-background-clip: text;
	background-clip: text;
	-webkit-text-fill-color: transparent;
	/* color: #2ac18a; */
}

.trusted-section {
	padding: 5rem 6% 3rem;
	text-align: center;
	background-color: #161616;
	position: relative;
	overflow: hidden;
}

.trusted-section::before {
	content: '';
	position: absolute;
	top: 0;
	left: -10%;
	width: 120%;
	height: 1px;
	background: linear-gradient(90deg, transparent, rgba(42, 193, 138, 0.3), transparent);
}

.section-title {
	font-size: 2rem;
	margin-bottom: 1rem;
	position: relative;
	display: inline-block;
}

.section-title span {
	background: linear-gradient(135deg, #2ac18a 0%, #1a8062 100%);
	-webkit-background-clip: text;
	background-clip: text;
	-webkit-text-fill-color: transparent;
}

.section-description {
	max-width: 600px;
	margin: 0 auto 3rem;
	color: #b8b8b8;
	font-size: 1.1rem;
	line-height: 1.6;
}

.partners-slider-container {
	position: relative;
	width: 100%;
	overflow: hidden;
	padding: 2rem 0;
	margin-bottom: 2rem;
}

.partners-slider-container::before,
.partners-slider-container::after {
	content: '';
	position: absolute;
	top: 0;
	width: 150px;
	height: 100%;
	z-index: 2;
	pointer-events: none;
}

.partners-slider-container::before {
	left: 0;
	background: linear-gradient(90deg, #161616, transparent);
}

.partners-slider-container::after {
	right: 0;
	background: linear-gradient(270deg, #161616, transparent);
}

.partners-track {
	display: flex;
	animation: scroll 30s linear infinite;
	width: max-content;
}

.partners-track:hover {
	animation-play-state: paused;
}

@keyframes scroll {
	0% {
		transform: translateX(0);
	}

	100% {
		transform: translateX(-50%);
	}
}

.partner-logo {
	width: 180px;
	height: 100px;
	/* background-color: #1e1e1e; */
	border-radius: 12px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	overflow: hidden;
	margin: 0 2rem;
	flex-shrink: 0;
	transition: transform 0.3s, box-shadow 0.3s;
	cursor: pointer;
}
/*
.partner-logo:hover {
	transform: translateY(-5px) scale(1.05);
	box-shadow: 0 10px 25px rgba(42, 193, 138, 0.2);
	z-index: 5;
} */

.partner-logo::after {
	content: '';
	position: absolute;
	top: 0;
	left: -100%;
	width: 60%;
	height: 100%;
	background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.05), transparent);
	transition: left 0.7s;
}

/* .partner-logo:hover::after {
	left: 100%;
} */

.partner-logo img {
	max-width: 90%;
	max-height: 90%;
	/* filter: grayscale(100%) brightness(1.5); */
	transition: filter 0.3s;
}

.partner-logo:hover img {
	filter: grayscale(0%) brightness(1.2);
}

.counter-section {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	gap: 3rem;
	margin-top: 4rem;
}

.counter-box {
	text-align: center;
	transform: translateY(30px);
}

.counter-value {
	font-size: 3rem;
	font-weight: 700;
	color: white;
	margin-bottom: 0.5rem;
	position: relative;
	display: inline-block;
}

.counter-value::after {
	content: '';
	position: absolute;
	bottom: -10px;
	left: 50%;
	transform: translateX(-50%);
	width: 40px;
	height: 3px;
	background: #2ac18a;
	border-radius: 3px;
}

.counter-label {
	color: #b8b8b8;
	font-size: 1rem;
}

.cta-button {
	margin-top: 4rem;
	padding: 1rem 2.5rem;
	background: #2ac18a;
	color: #121212;
	font-weight: 600;
	border-radius: 8px;
	border: none;
	cursor: pointer;
	font-size: 1rem;
	transition: transform 0.3s, box-shadow 0.3s;
	opacity: 0;
	transform: translateY(30px);
}

.cta-button:hover {
	transform: translateY(-3px);
	box-shadow: 0 10px 20px rgba(42, 193, 138, 0.3);
}

@keyframes fadeInUp {
	from {
		opacity: 0;
		transform: translateY(30px);
	}

	to {
		opacity: 1;
		transform: translateY(0);
	}
}

.why-section {
	padding: 7rem 6% 5rem;
	background-color: #121212;
	position: relative;
	overflow: hidden;
}

.why-section::before {
	content: '';
	position: absolute;
	top: 0;
	left: -10%;
	width: 120%;
	height: 1px;
	background: linear-gradient(90deg, transparent, rgba(42, 193, 138, 0.3), transparent);
}

.section-header {
	text-align: center;
	margin-bottom: 4rem;
}

.features-container {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	gap: 2rem;
	margin-bottom: 5rem;
}

.feature-card {
	background-color: #1a1a1a;
	border-radius: 16px;
	padding: 2rem;
	transition: transform 0.3s, box-shadow 0.3s;
	position: relative;
	overflow: hidden;
	height: 100%;
}

.feature-card::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 4px;
	height: 0;
	background: #2ac18a;
	transition: height 0.3s ease;
}

.feature-card:hover {
	transform: translateY(-10px);
	box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
}

.feature-card:hover::before {
	height: 100%;
}

.feature-icon {
	color: #2ac18a;
	margin-bottom: 1.5rem;
	position: relative;
	width: 50px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(42, 193, 138, 0.1);
	border-radius: 50%;
}

.feature-title {
	font-size: 1.3rem;
	margin-bottom: 1rem;
	font-weight: 600;
	color: #ffffff;
}

.feature-description {
	color: #b8b8b8;
	line-height: 1.6;
	font-size: 0.95rem;
}

.feature-title {
	font-size: 1.3rem;
	margin-bottom: 1rem;
	font-weight: 600;
	color: #ffffff;
}

.feature-description {
	color: #b8b8b8;
	line-height: 1.6;
	font-size: 0.95rem;
}

.testimonial-container {
	display: flex;
	justify-content: center;
	margin-top: 3rem;
}

.testimonial-card {
	background-color: #1a1a1a;
	border-radius: 16px;
	padding: 3rem;
	max-width: 700px;
	position: relative;
	box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
}

.testimonial-quote {
	position: absolute;
	top: 2rem;
	left: 2rem;
	color: #2ac18a;
}

.testimonial-text {
	font-size: 1.2rem;
	line-height: 1.8;
	margin-bottom: 2rem;
	color: #e0e0e0;
	font-style: italic;
}

.testimonial-author {
	display: flex;
	align-items: center;
}

.author-image {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	margin-right: 1rem;
	border: 2px solid #2ac18a;
}

.author-name {
	font-weight: 600;
	color: #ffffff;
}

.author-title {
	color: #b8b8b8;
	font-size: 0.9rem;
}

.solutions-section {
	padding: 7rem 6% 5rem;
	background-color: #121212;
	position: relative;
	overflow: hidden;
}

.solutions-section::before {
	content: '';
	position: absolute;
	top: 0;
	left: -10%;
	width: 120%;
	height: 1px;
	background: linear-gradient(90deg, transparent, rgba(42, 193, 138, 0.3), transparent);
}

.section-header {
	text-align: center;
	margin-bottom: 4rem;
}

.solutions-tabs {
	max-width: 1200px;
	margin: 0 auto;
}

.tab-buttons {
	display: flex;
	justify-content: center;
	gap: 1rem;
	margin-bottom: 3rem;
}

.tab-button {
	display: flex;
	align-items: center;
	gap: 0.8rem;
	padding: 1rem 1.5rem;
	background-color: #1a1a1a;
	border: none;
	border-radius: 10px;
	color: #b8b8b8;
	font-weight: 600;
	cursor: pointer;
	transition: all 0.3s ease;
}

.tab-button svg {
	transition: all 0.3s ease;
}

.tab-button:hover {
	background-color: #252525;
	color: #ffffff;
}

.tab-button.active {
	background: rgba(42, 193, 138, 0.15);
	color: #ffffff;
	box-shadow: 0 4px 15px rgba(42, 193, 138, 0.2);
}

.tab-button.active svg {
	color: #2ac18a;
}

.tab-content-container {
	position: relative;
	min-height: 400px;
}

.tab-content {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.3s ease, visibility 0.3s ease;
}

.tab-content.active {
	opacity: 1;
	visibility: visible;
	position: relative;
}

/* .solution-content {
	display: flex;
	gap: 4rem;
	align-items: center;
	background-color: #1a1a1a;
	border-radius: 16px;
	overflow: hidden;
	box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.solution-image {
	flex: 1;
	min-width: 400px;
	max-width: 40%;
	overflow: hidden;
}

.solution-image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	transition: transform 0.5s ease;
}

.solution-content:hover .solution-image img {
	transform: scale(1.05);
}

.solution-details {
	flex: 1.5;
	padding: 3rem 3rem 3rem 0;
}

.solution-title {
	font-size: 2rem;
	margin-bottom: 1rem;
	color: #ffffff;
	font-weight: 700;
}

.solution-description {
	color: #b8b8b8;
	font-size: 1.1rem;
	line-height: 1.6;
	margin-bottom: 2rem;
}

.solution-features {
	list-style: none;
	margin-bottom: 2.5rem;
}

.solution-features li {
	display: flex;
	margin-bottom: 1.2rem;
} */

.feature-bullet {
	width: 12px;
	height: 12px;
	border-radius: 50%;
	background: #2ac18a;
	margin-right: 1rem;
	margin-top: 0.5rem;
	flex-shrink: 0;
}

.feature-text h4 {
	color: #ffffff;
	margin-bottom: 0.3rem;
	font-size: 1.1rem;
	font-weight: 600;
}

.feature-text p {
	color: #b8b8b8;
	font-size: 0.95rem;
	line-height: 1.5;
}

.solution-cta {
	padding: 1rem 2rem;
	background: #2ac18a;
	color: #121212;
	border: none;
	border-radius: 8px;
	font-weight: 600;
	cursor: pointer;
	transition: all 0.3s ease;
}

.solution-cta:hover {
	transform: translateY(-3px);
	box-shadow: 0 8px 15px rgba(42, 193, 138, 0.3);
}

.faq-section {
	padding: 7rem 6% 5rem;
	background-color: #121212;
	position: relative;
	overflow: hidden;
}

.faq-section::before {
	content: '';
	position: absolute;
	top: 0;
	left: -10%;
	width: 120%;
	height: 1px;
	background: linear-gradient(90deg, transparent, rgba(42, 193, 138, 0.3), transparent);
}

.faq-container {
	max-width: 800px;
	margin: 0 auto;
}

.faq-item {
	background-color: #1a1a1a;
	border-radius: 10px;
	margin-bottom: 1.5rem;
	overflow: hidden;
	transition: all 0.3s ease;
}

.faq-item:hover {
	box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
}

.faq-question {
	padding: 1.5rem;
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.faq-question h3 {
	font-size: 1.1rem;
	color: #ffffff;
	font-weight: 600;
	margin: 0;
}

.faq-icon {
	color: #2ac18a;
	transition: transform 0.3s ease;
}

.faq-answer {
	padding: 0 1.5rem 1.5rem;
	color: #b8b8b8;
	line-height: 1.6;
	display: none;
}

/* Show answer by default for first FAQ item */
.faq-item:first-child .faq-answer {
	display: block;
}

.faq-item:first-child .faq-icon {
	transform: rotate(45deg);
}

.faq-cta {
	text-align: center;
	margin-top: 3rem;
}

.faq-cta p {
	color: #b8b8b8;
	margin-bottom: 1rem;
}

/* Footer Styles */
.footer {
	background-color: #161616;
	color: #b8b8b8;
	padding: 7rem 6% 5rem;
	position: relative;
	overflow: hidden;
}

.footer::before {
	content: '';
	position: absolute;
	top: 0;
	left: -10%;
	width: 120%;
	height: 1px;
	background: linear-gradient(90deg, transparent, rgba(42, 193, 138, 0.3), transparent);
}

.footer-top {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	gap: 3rem;
	margin-bottom: 4rem;
}

.footer-logo {
	display: flex;
	align-items: center;
	font-weight: 700;
	font-size: 1.3rem;
	margin-bottom: 1rem;
	color: #2ac18a;
}

.logo-icon {
	margin-right: 8px;
	display: flex;
	align-items: center;
	color: #2ac18a;
}

.footer-description {
	margin-bottom: 1.5rem;
	line-height: 1.6;
	font-size: 0.95rem;
}

.social-links {
	display: flex;
	gap: 1rem;
}

.social-link {
	color: #b8b8b8;
	background-color: #1a1a1a;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 0.3s ease;
}

.social-link:hover {
	background-color: #2ac18a;
	color: #121212;
	transform: translateY(-3px);
}

.footer-heading {
	color: #ffffff;
	font-size: 1.1rem;
	margin-bottom: 1.5rem;
	position: relative;
	display: inline-block;
}

.footer-heading::after {
	content: '';
	position: absolute;
	bottom: -8px;
	left: 0;
	width: 30px;
	height: 2px;
	background: #2ac18a;
}

.footer-links {
	list-style: none;
}

.footer-links li {
	margin-bottom: 0.8rem;
}

.footer-links a {
	color: #b8b8b8;
	text-decoration: none;
	transition: color 0.3s;
	position: relative;
	display: inline-block;
}

.footer-links a::after {
	content: '';
	position: absolute;
	bottom: -3px;
	left: 0;
	width: 0;
	height: 1px;
	background: #2ac18a;
	transition: width 0.3s;
}

.footer-links a:hover {
	color: #2ac18a;
}

.footer-links a:hover::after {
	width: 100%;
}

.newsletter-text {
	margin-bottom: 1rem;
	line-height: 1.6;
	font-size: 0.95rem;
}

.newsletter-form {
	display: flex;
	margin-bottom: 1.5rem;
}

.newsletter-input {
	flex: 1;
	padding: 0.8rem 1rem;
	border-radius: 8px 0 0 8px;
	border: none;
	background-color: #1a1a1a;
	color: #ffffff;
	outline: none;
}

.newsletter-button {
	background: #2ac18a;
	border: none;
	border-radius: 0 8px 8px 0;
	color: #121212;
	padding: 0 1rem;
	cursor: pointer;
	transition: all 0.3s;
}

.newsletter-button:hover {
	opacity: 0.9;
}

.app-buttons {
	display: flex;
	gap: 0.8rem;
}

.app-button {
	display: flex;
	align-items: center;
	gap: 0.5rem;
	padding: 0.6rem 1rem;
	background-color: #1a1a1a;
	border-radius: 8px;
	color: #b8b8b8;
	text-decoration: none;
	font-size: 0.85rem;
	transition: all 0.3s;
}

.app-button:hover {
	background-color: #252525;
	color: #ffffff;
	transform: translateY(-2px);
}

.footer-bottom {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-top: 2rem;
	border-top: 1px solid #252525;
	flex-wrap: wrap;
	gap: 1rem;
}

.copyright {
	font-size: 0.9rem;
}

.footer-bottom-links {
	display: flex;
	gap: 1.5rem;
}

.footer-bottom-links a {
	color: #b8b8b8;
	text-decoration: none;
	font-size: 0.9rem;
	transition: color 0.3s;
}

.footer-bottom-links a:hover {
	color: #2ac18a;
}

/* Testimonials Section */
.testimonials-section {
	padding: 7rem 6% 5rem;
	background-color: #121212;
	position: relative;
	overflow: hidden;
}

.testimonials-section::before {
	content: '';
	position: absolute;
	top: 0;
	left: -10%;
	width: 120%;
	height: 1px;
	background: linear-gradient(90deg, transparent, rgba(42, 193, 138, 0.3), transparent);
}

.testimonials-container {
	display: flex;
	gap: 30px;
	flex-wrap: wrap;
	justify-content: center;
	margin-top: 40px;
}

.testimonial-card {
	background-color: #1a1a1a;
	border-radius: 16px;
	padding: 3rem;
	max-width: 700px;
	position: relative;
	box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
}

.testimonial-card:hover {
	transform: translateY(-5px);
	box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
}

.testimonial-quote {
	position: absolute;
	top: -20px;
	left: 30px;
	width: 42px;
	height: 42px;
	border-radius: 50%;
	background-color: rgba(42, 193, 138, 0.2);
	display: flex;
	align-items: center;
	justify-content: center;
}

.quote-icon {
	color: white;
}

/* Analytics Section */
.analytics-section {
	padding: 7rem 6% 5rem;
	background-color: #121212;
	position: relative;
	overflow: hidden;
}

.analytics-section::before {
	content: '';
	position: absolute;
	top: 0;
	left: -10%;
	width: 120%;
	height: 1px;
	background: linear-gradient(90deg, transparent, rgba(42, 193, 138, 0.3), transparent);
}

.analytics-section .section-title,
.analytics-section .section-description {
	color: white;
}

.analytics-container {
	display: flex;
	gap: 30px;
	flex-wrap: wrap;
	justify-content: center;
	margin-top: 50px;
}

.analytics-card {
	background-color: rgba(255, 255, 255, 0.1);
	border-radius: 12px;
	padding: 30px;
	flex: 1;
	min-width: 260px;
	max-width: 320px;
	display: flex;
	gap: 20px;
	backdrop-filter: blur(10px);
	transition: transform 0.3s ease;
}

.analytics-card:hover {
	transform: translateY(-5px);
	background-color: rgba(255, 255, 255, 0.15);
}

.analytics-icon {
	background-color: rgba(42, 193, 138, 0.2);
	border-radius: 50%;
	width: 60px;
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.analytics-content {
	flex: 1;
}

.analytics-value {
	font-size: 32px;
	font-weight: 700;
	line-height: 1.2;
}

.analytics-label {
	font-size: 18px;
	font-weight: 600;
	margin-top: 5px;
	margin-bottom: 10px;
}

.analytics-description {
	font-size: 14px;
	opacity: 0.8;
	line-height: 1.5;
}

.analytics-cta {
	display: flex;
	justify-content: center;
	gap: 15px;
	margin-top: 50px;
}

.analytics-cta .btn-primary {
	background-color: #2ac18a;
	color: #121212;
}

.analytics-cta .btn-outline {
	border-color: #2ac18a;
	color: #2ac18a;
}

.analytics-cta .btn-primary:hover {
	background-color: rgba(42, 193, 138, 0.9);
}

.analytics-cta .btn-outline:hover {
	background-color: rgba(42, 193, 138, 0.1);
}

/* Responsive adjustments */
@media (max-width: 768px) {
	.testimonials-container,
	.analytics-container {
		flex-direction: column;
		align-items: center;
	}

	.analytics-card {
		max-width: 100%;
	}
}

/* Responsive Styles */
@media (max-width: 768px) {
	.footer-top {
		grid-template-columns: 1fr;
		gap: 2rem;
	}

	.footer-bottom {
		flex-direction: column;
		text-align: center;
	}

	.footer-bottom-links {
		justify-content: center;
	}
	.hero-subtitle {
		font-size: 1rem !important;
	}
}

@media (max-width: 992px) {
	.app-buttons {
		flex-direction: column;
	}
}

@media (max-width: 1024px) {
	.solution-content {
		flex-direction: column;
		gap: 2rem;
	}

	.solution-image {
		max-width: 100%;
		min-width: 100%;
	}

	.solution-details {
		padding: 0 2rem 2rem;
	}
}

@media (max-width: 768px) {
	.tab-buttons {
		flex-direction: column;
		width: 100%;
	}

	.tab-button {
		width: 100%;
		justify-content: center;
	}

	.solution-title {
		font-size: 1.5rem;
	}
}

@media (max-width: 768px) {
	.features-container {
		grid-template-columns: 1fr;
	}

	.testimonial-card {
		padding: 2rem;
	}

	.testimonial-text {
		font-size: 1rem;
	}
}

/* Responsive styles */
@media (max-width: 768px) {
	.partner-logo {
		width: 140px;
		height: 70px;
		margin: 0 1.5rem;
	}

	.counter-section {
		gap: 2rem;
	}

	.counter-value {
		font-size: 2.5rem;
	}
}

@media (max-width: 992px) {
	.nav-links {
		display: none;
	}

	.mobile-menu-btn {
		display: block;
	}

	.hero {
		flex-direction: column;
		text-align: center;
		padding: 4rem 6%;
	}

	.hero-content {
		margin-bottom: 3rem;
	}

	.hero-buttons {
		justify-content: center;
	}

	.hero-image {
		max-width: 100%;
	}

	.hero-title {
		font-size: 2.25rem;
	}
}

@media (max-width: 768px) {
	.nav-actions .btn-outline {
		display: none;
	}
}

.solutions-section {
	padding: 7rem 6% 5rem;
	background-color: #121212;
	position: relative;
	overflow: hidden;
}

.section-header {
	text-align: center;
	margin-bottom: 40px;
}

/* .section-title {
	font-size: 36px;
	font-weight: 700;
	margin-bottom: 16px;
	color: #fff;
} */

.solutions-tabs {
	max-width: 1200px;
	margin: 0 auto;
}

.tab-buttons {
	display: flex;
	justify-content: center;
	margin-bottom: 32px;
	gap: 12px;
}

.tab-button {
	display: flex;
	align-items: center;
	gap: 8px;
	padding: 12px 24px;
	background-color: rgba(255, 255, 255, 0.1);
	border: none;
	border-radius: 8px;
	color: #ccc;
	font-size: 16px;
	font-weight: 500;
	cursor: pointer;
	transition: all 0.3s ease;
}

.tab-button svg {
	stroke: #ccc;
	transition: stroke 0.3s ease;
}

.tab-button.active {
	background-color: rgba(42, 193, 138, 0.2);
	color: #2ac18a;
}

.tab-button.active svg {
	stroke: #2ac18a;
}

.tab-button:hover {
	background-color: rgba(255, 255, 255, 0.15);
}

.tab-content {
	display: none;
}

.tab-content.active {
	display: block;
}

.cards-grid {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 20px;
	margin-bottom: 32px;
}

@media (max-width: 1200px) {
	.cards-grid {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media (max-width: 640px) {
	.cards-grid {
		grid-template-columns: 1fr;
	}
}

.solution-card {
	display: flex;
	flex-direction: column;
	background-color: #222;
	border-radius: 12px;
	overflow: hidden;
	transition: transform 0.3s ease, box-shadow 0.3s ease;
	height: 100%;
	box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
}

.solution-card:hover {
	transform: translateY(-5px);
	box-shadow: 0 8px 24px rgba(0, 0, 0, 0.3);
}

.card-image {
	width: 100%;
	height: 200px;
	overflow: hidden;
	position: relative;
}

.card-image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	transition: transform 0.5s ease;
}

.solution-card:hover .card-image img {
	transform: scale(1.05);
}

.card-content {
	padding: 20px;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
}

.card-title {
	font-size: 18px;
	font-weight: 600;
	color: #fff;
	margin: 0 0 12px 0;
}

.card-subtitle {
	font-size: 14px;
	color: #aaa;
	margin: 0;
	line-height: 1.5;
}

.tab-cta-container {
	display: flex;
	justify-content: center;
	margin-top: 24px;
}

.solution-cta {
	padding: 12px 30px;
	background: linear-gradient(135deg, #2ac18a 0%, #1a8062 100%);
	color: white;
	border: none;
	border-radius: 30px;
	font-size: 16px;
	font-weight: 600;
	cursor: pointer;
	transition: all 0.3s ease;
}

.solution-cta:hover {
	transform: translateY(-2px);
	box-shadow: 0 4px 12px rgba(42, 193, 138, 0.3);
}

.mobile-menu-btn {
	display: none;
	background: none;
	border: none;
	cursor: pointer;
	color: #b8b8b8;
}

.mobile-menu-overlay {
	display: none;
}

@media screen and (max-width: 768px) {
	.nav-links {
		display: none;
	}

	.mobile-menu-btn {
		display: block;
	}

	.mobile-menu-overlay {
		display: block;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.8);
		z-index: 1100;
	}

	.mobile-menu {
		position: fixed;
		top: 0;
		right: 0;
		width: 250px;
		height: 100%;
		background-color: #1a1a1a;
		padding: 2rem;
		box-shadow: -2px 0 10px rgba(0, 0, 0, 0.3);
		animation: slideIn 0.3s ease-out;
	}

	.mobile-menu-close {
		position: absolute;
		top: 1rem;
		right: 1rem;
		background: none;
		border: none;
		color: #b8b8b8;
		font-size: 2rem;
		cursor: pointer;
	}

	.mobile-nav-links {
		list-style: none;
		display: flex;
		flex-direction: column;
		gap: 1.5rem;
		margin-top: 2rem;
	}

	.mobile-nav-link {
		text-decoration: none;
		color: #b8b8b8;
		font-weight: 500;
		font-size: 1.1rem;
		transition: color 0.3s;
	}

	.mobile-nav-link:hover {
		color: #ffffff;
	}

	.mobile-login-btn {
		width: 100%;
		padding: 0.75rem;
		border: 2px solid #2ac18a;
		background: none;
		color: #2ac18a;
		border-radius: 8px;
		font-weight: 600;
		margin-top: 1rem;
		cursor: pointer;
		transition: all 0.3s;
	}

	.mobile-login-btn:hover {
		background-color: #2ac18a;
		color: #1a1a1a;
	}

	@keyframes slideIn {
		from {
			transform: translateX(100%);
		}
		to {
			transform: translateX(0);
		}
	}
}


